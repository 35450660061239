import { Helmet } from 'react-helmet-async';
import { forwardRef } from 'react';
import {
  Link as RouterLink,
  useLocation,
  useNavigate
} from "react-router-dom";

import { Box, Button, Container, Stack, Typography } from '@mui/material';
import { Add } from '@mui/icons-material';
import { useIntl } from 'react-intl';

interface Props {
  children: React.ReactNode | React.ReactElement | React.ReactNode[] | React.ReactElement[];
  meta?: any;
  title?: string;
  hideToolbar?: boolean
  isList?: boolean
  onCreate?: () => void;
  createUrl?:string;
}

const PageLayout = forwardRef(({ children, isList, title, hideToolbar, createUrl, meta, onCreate, ...other }: Props, ref: any) => {
  const { formatMessage } = useIntl();
  const nagivate = useNavigate();
  let match = useLocation();
  const url = createUrl ? createUrl : `${match.pathname}/create`;
  return <>
    <Helmet>
      <title>{`${title} | Admin Panel`}</title>
      {meta}
    </Helmet>

    <Box ref={ref} {...other}>
      <Container maxWidth="xl" style={{ maxWidth: '100%' }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          {title ?
            <Typography variant="h5" color="primary" gutterBottom>
              {title} {isList && formatMessage({ id: "list" })}
            </Typography>
            : <Box></Box>}
          {!hideToolbar &&
            <Button variant="contained" onClick={() => onCreate ? onCreate() : nagivate(url)} startIcon={<Add />}>
              Create
            </Button>
          }
        </Stack>
        {children}
      </Container>
    </Box>
  </>
});

PageLayout.defaultProps = {
  title: '',
  hideToolbar: false,
  isList: true
}

export default PageLayout;
