import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { DrawerProps } from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { styled } from '@mui/system';
import { CloseRounded } from '@mui/icons-material';
import { IconButton, Modal } from '@mui/material';
import useResponsive from 'hooks/useResponsive';

interface Props {
    open: boolean;
    children: React.ReactElement | React.ReactElement[];
    title?: string | ReactNode;
    onClose: () => void;
    onSubmit?: () => void;
    cancelLabel?: string | ReactNode;
    size: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
    hideHeader?: boolean;
}

const DrawerHeader = styled('div')(({ theme }: any) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 3),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    overflow: 'visible',
    // paddingX: 2,
};

const ModalHeader = styled(Typography)(({ theme }: any) => ({
    padding: theme.spacing(2, 2),
    background: theme.palette.primary.lighter,
    display: 'flex',
    justifyContent: 'space-between'
}));

const CustomModal = (props: Props & DrawerProps) => {
    const { children, title, open, onClose, size, hideHeader } = props;
    const isDesktop = useResponsive("up", "lg");

    let width: any = '800px';
    if (isDesktop) {
        if (size === 'xs') { width = '1000px' }
        else if (size === 'sm') { width = '800px' }
        else if (size === 'md') { width = '500px' }
        else if (size === 'lg') { width = '300px' }
        else if (size === 'xl') { width = '60px' }
    } else {
        if (size === 'xs') { width = '50px' }
        else if (size === 'sm') { width = '80px' }
        else if (size === 'md') { width = '100px' }
        else if (size === 'lg') { width = '200px' }
        else if (size === 'xl') { width = '60px' }
    }


    return (
        <Modal
            open={open}
            hideBackdrop={false}
            onClose={onClose}
        >
            <Box
                sx={{
                    width: `calc(100% - ${width})`,
                    height: 'calc(100vh - 50px)',
                    ...style
                }}
            >
                <Box
                    sx={title ? {
                        display: 'flex',
                        justifyContent: "space-between",
                        alignItems: 'center',
                        padding: 1,
                        borderBottom: '1px solid #DDD'
                    } : {}}
                >
                    {title ? <Typography variant='h6'> {title} </Typography> : <div />}
                    <IconButton
                        disableRipple
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: -25,
                            top: -25,
                            color: 'white',
                            background: 'red',
                            zIndex: 1300,
                            fontSize: 34,
                            boxShadow: 3
                        }}
                    >
                        <CloseRounded />
                    </IconButton>
                </Box>
                <Box
                    sx={{
                        height: '100%',
                        width: '100%',
                        // marginTop: 2,
                        overflowY: 'auto'
                    }}
                >
                    {children}
                </Box>
            </Box>
        </Modal>
    )
};

CustomModal.defaultProps = {
    anchor: "right",
    size: 'xs',
    hideHeader: false,
    cancelLabel: <FormattedMessage id="close" />
}

export { CustomModal };