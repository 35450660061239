import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
// material
import { Button, Card, CardActions, CardMedia, Grid } from '@mui/material';

import { CustomFileUpload } from 'components/forms';
import { CustomDrawer, HoverLoader } from 'components';
import { useToastMessage } from 'hooks';
import { services } from 'redux/services';
import { FileAcceptData, fileValidate } from 'utils/constants';
import { CustomDeleteButton } from 'components/button';
import { Save } from '@mui/icons-material';

interface FileData {
    created_at: string;
    file_extension: string;
    file_name: string;
    file_path: string;
    file_path_url: string;
    file_size: number;
    gallery_id: string;
    id: number;
    type: string;
    updated_at: string;
}

interface Props {
    open: boolean;
    closeModal: () => void;
    formMode: 'create';
    selectedData: any;
    headerText: string;
    refreshData: () => void;
}

const Form = (props: Props) => {
    const { closeModal, open, formMode, selectedData, headerText, refreshData } = props;
    const { toastMessage } = useToastMessage();
    const { handleSubmit, formState: { errors }, setValue, register, clearErrors, reset } = useForm<any>({
        mode: 'all',
    });

    const { formatMessage } = useIntl();
    const [loading, setLoading] = useState(false);
    const [fileArray, setFileArray] = useState<Array<FileData>>([]);
    const [wasTriggered, setWasTriggered] = useState(false);

    useEffect(() => {
        if (selectedData?.images?.length) {
            setFileArray(selectedData?.images)
        }
    }, [selectedData])

    function onSubmit(data: any) {
        setLoading(true);
        setWasTriggered(false);
        if (data?.file?.length > 0) {
            const message = 'save-message';
            data.file = data?.file?.[0];
            data.gallery_id = selectedData.id;
            services.createGalleryImage(data)
                .then((res: any) => {
                    setLoading(false);
                    setValue(`file`, null);
                    clearErrors(`file`);
                    const fileRes = res.result as FileData;
                    if (fileRes) {
                        setFileArray([...fileArray, fileRes]);
                    }
                    setWasTriggered(true);
                    toastMessage(formatMessage({ id: message }, { title: formatMessage({ id: 'gallery' }) }));
                })
                .catch((err: any) => {
                    setLoading(false);
                    toastMessage(JSON.stringify(err?.data?.message) || 'Something Wrong', 'error');
                })
        } else {
            setLoading(false);
            toastMessage('Please upload image', 'error');
        }
    }


    function onDelete(ids: Array<string>) {
        const message = 'deleted-message';
        services.deleteGalleryImage({
            ids,
        }).then((res: any) => {
            setFileArray(fileArray?.filter((x: any) => !ids.includes(x.id)));
            toastMessage(formatMessage({ id: message }, { title: formatMessage({ id: 'file' }) }));
        })
            .catch((err: any) => {
                toastMessage(err?.data?.message || 'Something Wrong', 'error');
            })
    }


    return (
        <CustomDrawer
            formMode={formMode}
            open={open}
            onCancel={() => {
                closeModal();
                refreshData();
            }}
            // onSubmit={handleSubmit(onSubmit)}
            title={headerText}
            size='xl'
        >
            <Grid container spacing={2}>
                {loading && <HoverLoader />}
                <Grid item xs={4}>
                    <CustomFileUpload
                        // isPreviewImage={false}
                        accept={FileAcceptData.IMAGES}
                        label={formatMessage({ id: "image" })}
                        error={errors?.file}
                        {...register('file', {
                            validate: (value: any) => value?.length > 0 ? fileValidate(value?.[0]) : true,
                        })}
                        onClear={() => {
                            setValue(`file`, null);
                            clearErrors(`file`);
                        }}
                        wasTriggered={wasTriggered}
                        onChange={(file: any) => setValue('file', file)}
                    />
                </Grid>
                <Grid item lg={2} md={6} sm={12} xs={12}>
                    <Button variant="contained" type='submit' onClick={handleSubmit(onSubmit)} startIcon={<Save />} fullWidth>
                        Upload Image
                    </Button>
                </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ marginTop: 2 }}>
                {fileArray?.length > 0 && fileArray?.map((item: any, index: number) =>
                    <Grid item lg={3} md={6} sm={6} xs={6} key={index}>
                        <Card sx={{ maxWidth: 500 }}>
                            <CardMedia
                                sx={{ height: 300, backgroundSize: 'contain', cursor: 'pointer' }}
                                onClick={() => window.open(item.file_path_url, "_blank")}
                                image={item.file_path_url}
                                title="Medical"
                            />
                            <CardActions>
                                <CustomDeleteButton
                                    variant="outlined"
                                    label="Remove"
                                    fullWidth
                                    onDelete={() => onDelete([item.id])}
                                />
                            </CardActions>
                        </Card>
                    </Grid>
                )}

            </Grid>
        </CustomDrawer>
    )
}

export default Form;