import React, { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
// material
import { Box, Button, Grid, IconButton } from '@mui/material';

import { CustomCheckBox, CustomFileUpload, CustomTextArea, CustomTextBox } from 'components/forms';
import { CustomDrawer, HoverLoader } from 'components';
import { validationRule } from 'utils/global';
import { useToastMessage } from 'hooks';
import { services } from 'redux/services';
import { FileAcceptData, fileValidate } from 'utils/constants';
import { Add, Delete } from '@mui/icons-material';

interface Props {
    open: boolean;
    closeModal: () => void;
    formMode: 'create' | 'update';
    selectedData: any;
    headerText: string;
    refreshData: () => void;
}

const buttonInitialState = {
    name: '',
    url: '',
    hexcode: ''
}

const Form = (props: Props) => {
    const { closeModal, open, formMode, selectedData, headerText, refreshData } = props;
    const { toastMessage } = useToastMessage();
    const { handleSubmit, formState: { errors }, control, setValue, register, clearErrors, reset } = useForm<any>({
        mode: 'all',
        defaultValues: { is_active: 1, buttons: [buttonInitialState] }
    });
    const { fields, append, remove } = useFieldArray({
        control,
        name: "buttons",
    });

    const { formatMessage } = useIntl();
    const [loading, setLoading] = useState(false);
    const [fileImage, setFileImage] = useState({
        file: '',
    })

    function onSubmit(data: any) {
        setLoading(true);
        data.file = data?.file?.[0];
        data.is_active = data?.is_active ? 1 : 0;
        if (formMode === 'update') data.id = selectedData?.id;
        const apiFunc = formMode === 'create' ? 'createBanner' : 'updateBanner';
        const message = formMode === 'create' ? 'created-message' : 'updated-message';
        services[apiFunc](data)
            .then((res: any) => {
                setLoading(false);
                toastMessage(formatMessage({ id: message }, { title: "banner" }));
                closeModal();
                refreshData();
            })
            .catch((err: any) => {
                setLoading(false);
                toastMessage(err?.data?.message, 'error');
            })
    }

    //RESET DEFAULTVALUE IN UPDATE MODE
    useEffect(() => {
        if (selectedData) {
            reset({
                title: selectedData.title,
                sub_title: selectedData.sub_title,
                description: selectedData.description,
                buttons: selectedData.buttons ?? [buttonInitialState],
                is_active: selectedData.is_active,
                file: null,
            });
            setFileImage({ file: selectedData.file_path_url })
        }

    }, [formMode === 'update']);

    return (
        <CustomDrawer
            formMode={formMode}
            open={open}
            onCancel={closeModal}
            onSubmit={handleSubmit(onSubmit)}
            title={headerText}
            size='md'
        >
            <Grid container spacing={2}>
                {loading && <HoverLoader />}
                <Grid item xs={12}>
                    <CustomTextBox
                        label={formatMessage({ id: "title" })}
                        name="title"
                        control={control}
                        error={errors?.title}
                        rules={validationRule.textbox({ required: true })}
                    />
                </Grid>
                {/* <Grid item xs={12}>
                    <CustomTextBox
                        label={formatMessage({ id: "sub-title" })}
                        name="sub_title"
                        control={control}
                        error={errors?.sub_title}
                        rules={validationRule.textbox({ required: true })}
                    />
                </Grid> */}
                <Grid item xs={12}>
                    <CustomTextArea
                        formLabel={formatMessage({ id: "description" })}
                        name="description"
                        control={control}
                        minRows={6}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CustomFileUpload
                        accept={FileAcceptData.IMAGES}
                        label={formatMessage({ id: "image" })}
                        error={errors?.file}
                        {...register('file', {
                            validate: (value: any) => value?.length > 0 ? fileValidate(value?.[0]) : true,
                        })}
                        defaultFileUrl={fileImage.file}
                        onClear={() => {
                            setValue(`file`, null);
                            clearErrors(`file`);
                        }}
                        onChange={(file: any) => setValue('file', file)}
                    />
                </Grid>
                <Grid item xs={12}>
                    {fields.map((field, index) => (
                        <Box key={field.id} sx={{ display: 'flex', gap: 1, marginY: 1 }}>
                            <CustomTextBox
                                label={formatMessage({ id: "name" })}
                                name={`buttons.${index}.name`}
                                control={control}
                            />
                            <CustomTextBox
                                label={formatMessage({ id: "url" })}
                                name={`buttons.${index}.url`}
                                control={control}
                            />
                            <CustomTextBox
                                label={formatMessage({ id: "hexcode" })}
                                name={`buttons.${index}.hexcode`}
                                control={control}
                            />
                            <IconButton onClick={() => remove(index)}>
                                <Delete color='error' />
                            </IconButton>
                        </Box>
                    ))}
                    <Button
                        startIcon={<Add />}
                        variant='contained'
                        onClick={() => append(buttonInitialState)}
                    >
                        Add Banner Buttons
                    </Button>
                </Grid>

                <Grid item lg={4} md={6} sm={12} xs={12}>
                    <CustomCheckBox
                        name="is_active"
                        label={formatMessage({ id: "is-active" })}
                        control={control}
                        isDefaultChecked
                    />
                </Grid>
            </Grid>
        </CustomDrawer>
    )
}

export default Form;