import { useState } from 'react';
import { NavLink as RouterLink, matchPath, useLocation } from 'react-router-dom';
// material
import { alpha, useTheme, styled } from '@mui/material/styles';
import { Box, List, Collapse, ListItemText, ListItemIcon, ListItemButton } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { RootReducerState } from 'utils/types';
import { useSelector } from 'react-redux';
import { findCommonItems } from 'utils/global';

const ListItemStyle = styled((props: any) => <ListItemButton disableGutters {...props} />)(({ theme }: any) => ({
  ...theme.typography.body2,
  height: 48,
  position: 'relative',
  textTransform: 'capitalize',
  color: theme.palette.text.secondary,
  borderRadius: theme.shape.borderRadius,
}));

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  color: 'inherit',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});


function NavItem({ item, active, userData }: any) {
  const theme = useTheme();

  const isActiveRoot = active(item.path);

  const { title, path, icon, info, children, role } = item;

  const [open, setOpen] = useState(isActiveRoot);

  const handleOpen = () => {
    setOpen((prev: boolean) => !prev);
  };

  const activeRootStyle = {
    color: 'primary.main',
    fontWeight: 'fontWeightMedium',
    bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
  };

  const activeSubStyle = {
    color: 'primary.light',
    fontWeight: 'fontWeightMedium',
  };


  const normalSubStyle = {
    color: 'primary.contrastText',
  };

  if (children) {
    return (
      <>
        <ListItemStyle
          onClick={handleOpen}
          sx={isActiveRoot ? activeRootStyle : normalSubStyle}
        >
          <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
          <ListItemText disableTypography primary={title} />
          {info && info}
          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </ListItemStyle>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((ch: any) => {
              const { title, path } = ch;
              const isActiveSub = active(path);

              return findCommonItems(ch.role, userData.role) && (
                <ListItemStyle
                  key={title}
                  component={RouterLink}
                  to={path}
                  sx={isActiveSub ? activeSubStyle : normalSubStyle}
                >
                  <ListItemIconStyle>
                    <Box
                      component="span"
                      sx={{
                        width: 4,
                        height: 4,
                        display: 'flex',
                        borderRadius: '50%',
                        alignItems: 'center',
                        justifyContent: 'center',
                        transition: (theme) => theme.transitions.create('transform'),
                        ...(isActiveSub ? {
                          transform: 'scale(2)',
                          bgcolor: 'primary.light',
                        } : {
                          transform: 'scale(2)',
                          bgcolor: 'primary.contrastText',
                        }),
                        // bgcolor: 'text.disabled',
                      }}
                    />
                  </ListItemIconStyle>
                  <ListItemText disableTypography primary={title}/>
                </ListItemStyle>
              );
            })}
          </List>
        </Collapse>
      </>
    );
  }

  return (
    <ListItemStyle
      component={RouterLink}
      to={path}
      sx={isActiveRoot ? activeRootStyle : normalSubStyle}
    // sx={{
    //   ...(isActiveRoot && activeRootStyle),
    // }}
    >
      <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
      <ListItemText disableTypography primary={title} />
      {info && info}
    </ListItemStyle>
  );
}

export default function NavSection({ navConfig, ...other }: any) {
  const { pathname } = useLocation();
  const { data } = useSelector(({ auth }: RootReducerState) => ({ data: auth.data }));

  const match = (path: any) => (path ? !!matchPath({ path, end: false }, pathname) : false);

  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {navConfig.map((item: any) => findCommonItems(item.role, data.role) && (
          <NavItem key={item.title} item={item} active={match} userData={data} />
        ))}
      </List>
    </Box>
  );
}
