import { Chip } from "@mui/material";
import { avatarOptions, ChipColumnOptions, dateOptions, FlagColumnOptions, genderOptions, labelOption, priceOption,BooleanIconOption } from ".";
import { statusOptions } from "..";

export const masterColumns = (formatMessage: any) => [
  {
    name: "code",
    label: formatMessage({ id: "code" }),
  },
  {
    name: "name",
    label: formatMessage({ id: "name" }),
  },
  {
    name: "is_active",
    label: formatMessage({ id: "is-active" }),
    ...ChipColumnOptions(statusOptions),
    disableSort: true,
    disableFilter: true,
  },
];

export const roleColumns = (formatMessage: any) => [
  {
    name: "name",
    label: formatMessage({ id: "name" }),
  },
  {
    name: "guard_name",
    label: formatMessage({ id: "guard-name" }),
  },
];

export const accountColumns = (formatMessage: any) => [
  {
    name: "logo_path",
    label: formatMessage({ id: "logo" }),
    ...avatarOptions,
    disableSort: true,
    disableFilter: true,
  },
  {
    name: "name",
    label: formatMessage({ id: "name" }),
    disableSort: true,
    disableFilter: true,
  },
  {
    name: "email",
    label: formatMessage({ id: "email" }),
    disableSort: true,
    disableFilter: true,
  },
  {
    name: "address",
    label: formatMessage({ id: "address" }),
    primaryColumnName: 'address_line1',
    disableSort: true,
    disableFilter: true,
  },
  {
    name: "phone",
    label: formatMessage({ id: "phone" }),
    disableSort: true,
    disableFilter: true,
  },
];

export const expenseColumns = (formatMessage: any) => [

  {
    name: "expense_item_detail",
    label: formatMessage({ id: "items" }),
    options: {
      customBodyRender: (value: any) => {
        const items = value?.map(({ title }: any) => title);
        return items?.map((item: any, index: number) => (
          <Chip key={index} label={item} />
        ));
      },
    },
  },
  {
    name: "total_amount",
    label: formatMessage({ id: "total-amount" }),
    ...priceOption
  },
  // {
  //   name: "discount_amount",
  //   label: formatMessage({ id: "discount-amount" }),
  //   ...priceOption
  // },
  {
    label: formatMessage({ id: "paid-amount" }),
    name: "paid_amount",
    ...priceOption
  },
  {
    name: "created_at",
    label: formatMessage({ id: "date" }),
    primaryColumnName: "dob_ad",
    ...dateOptions,
  }
];

export const expenseDetailsColumns = (formatMessage: any) => [
  {
    name: "code",
    label: formatMessage({ id: "code" }),
  },
  {
    name: "title",
    label: formatMessage({ id: "title" }),
  },
  {
    name: "qty",
    label: formatMessage({ id: "qty" }),
  },
  {
    name: "rate",
    label: formatMessage({ id: "rate" }),
  },
  {
    label: formatMessage({ id: "total-amount" }),
    name: "total_amount",
    ...priceOption
  },
  // {
  //   label: formatMessage({ id: "discount-amount" }),
  //   name: "discount_amount",
  //   ...priceOption
  // },
  {
    label: formatMessage({ id: "paid-amount" }),
    name: "paid_amount",
    ...priceOption
  }
];


export const bannerColumns = (formatMessage: any) => [
  {
    name: "file_path_url",
    label: formatMessage({ id: "image" }),
    ...avatarOptions,
    disableSort: true,
    disableFilter: true,
  },
  {
    name: "title",
    label: formatMessage({ id: "title" }),
    disableSort: true,
    disableFilter: true,
  },
  {
    name: "is_active",
    label: formatMessage({ id: "status" }),
    ...ChipColumnOptions(statusOptions),
    disableSort: true,
    disableFilter: true,
  },
];


export const categoryColumns = (formatMessage: any) => [
  {
    name: "file_path_url",
    label: formatMessage({ id: "image" }),
    ...avatarOptions,
    disableSort: true,
    disableFilter: true,
  },
  {
    name: "title",
    label: formatMessage({ id: "title" }),
    disableSort: true,
    disableFilter: true,
  },
  {
    name: "is_active",
    label: formatMessage({ id: "status" }),
    ...ChipColumnOptions(statusOptions),
    disableSort: true,
    disableFilter: true,
  },
];

export const teamColumns = (formatMessage: any) => [
  {
    name: "full_name",
    label: formatMessage({ id: "full-name" }),
    disableSort: true,
    disableFilter: true,
  },
  {
    name: "tags",
    label: formatMessage({ id: "department" }),
    disableSort: true,
    disableFilter: true,
  },
  {
    name: "designation",
    label: formatMessage({ id: "designation" }),
    disableSort: true,
    disableFilter: true,
  },
  {
    name: "order",
    label: formatMessage({ id: "order" }),
    disableSort: true,
    disableFilter: true,
  },
  {
    name: "is_active",
    label: formatMessage({ id: "status" }),
    ...ChipColumnOptions(statusOptions),
    disableSort: true,
    disableFilter: true,
  },
];

export const serviceColumns = (formatMessage: any) => [
  {
    name: "file_path_url",
    label: formatMessage({ id: "image" }),
    ...avatarOptions,
    disableSort: true,
    disableFilter: true,
  },
  {
    name: "category_title",
    label: formatMessage({ id: "category" }),
    disableSort: true,
    disableFilter: true,
  },
  {
    name: "title",
    label: formatMessage({ id: "title" }),
    disableSort: true,
    disableFilter: true,
  },
  {
    name: "is_featured",
    label: formatMessage({ id: "is-featured" }),
    disableSort: true,
    disableFilter: true,
  },
  {
    name: "is_active",
    label: formatMessage({ id: "status" }),
    ...ChipColumnOptions(statusOptions),
    disableSort: true,
    disableFilter: true,
  },
];


export const projectColumns = (formatMessage: any) => [
  {
    name: "file_path_url",
    label: formatMessage({ id: "image" }),
    ...avatarOptions,
    disableSort: true,
    disableFilter: true,
  },
  {
    name: "category_title",
    label: formatMessage({ id: "category" }),
    disableSort: true,
    disableFilter: true,
  },
  {
    name: "title",
    label: formatMessage({ id: "title" }),
    disableSort: true,
    disableFilter: true,
  },
  {
    name: "is_featured",
    label: formatMessage({ id: "is-featured" }),
    disableSort: true,
    disableFilter: true,
  },
  {
    name: "is_active",
    label: formatMessage({ id: "status" }),
    ...ChipColumnOptions(statusOptions),
    disableSort: true,
    disableFilter: true,
  },
];


export const userColumns = (formatMessage: any) => [
  // {
  //   name: "profile_photo_url",
  //   label: formatMessage({ id: "photo" }),
  //   ...avatarOptions,
  //   disableSort: true,
  //   disableFilter: true,
  // },
  {
    name: "email",
    label: formatMessage({ id: "email" }),
    disableSort: true,
    disableFilter: true,
  },
  {
    name: "full_name",
    label: formatMessage({ id: "name" }),
    disableSort: true,
    disableFilter: true,
  },
  {
    name: "phone",
    label: formatMessage({ id: "phone" }),
    disableSort: true,
    disableFilter: true,
  },
  {
    name: "role",
    label: formatMessage({ id: "role" }),
    disableSort: true,
    disableFilter: true,
  },
  {
    name: "is_active",
    label: formatMessage({ id: "status" }),
    ...ChipColumnOptions(statusOptions),
    disableSort: true,
    disableFilter: true,
  },
];


export const pageColumns = (formatMessage: any) => [
  {
    name: "file_path_url",
    label: formatMessage({ id: "image" }),
    ...avatarOptions,
    disableSort: true,
    disableFilter: true,
  },
  {
    name: "slug",
    label: formatMessage({ id: "slug" }),
    disableSort: true,
    disableFilter: true,
  },
  {
    name: "title",
    label: formatMessage({ id: "title" }),
    disableSort: true,
    disableFilter: true,
  },
  {
    name: "is_active",
    label: formatMessage({ id: "status" }),
    ...ChipColumnOptions(statusOptions),
    disableSort: true,
    disableFilter: true,
  },
];

export const testimonialColumns = (formatMessage: any) => [
  {
    name: "full_name",
    label: formatMessage({ id: "full-name" }),
    disableSort: true,
    disableFilter: true,
  },
  {
    name: "designation",
    label: formatMessage({ id: "designation" }),
    disableSort: true,
    disableFilter: true,
  },
  {
    name: "order",
    label: formatMessage({ id: "order" }),
    disableSort: true,
    disableFilter: true,
  },
  {
    name: "is_active",
    label: formatMessage({ id: "status" }),
    ...ChipColumnOptions(statusOptions),
    disableSort: true,
    disableFilter: true,
  },
];


export const enquiryColumns = (formatMessage: any) => [
 
  {
    name: "email",
    label: formatMessage({ id: "email" }),
    disableSort: true,
    disableFilter: true,
  },
  {
    name: "full_name",
    label: formatMessage({ id: "full-name" }),
    disableSort: true,
    disableFilter: true,
  },
  {
    name: "phone",
    label: formatMessage({ id: "phone" }),
    disableSort: true,
    disableFilter: true,
  },
  {
    name: "message",
    label: formatMessage({ id: "message" }),
    disableSort: true,
    disableFilter: true,
  },
];