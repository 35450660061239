import { Box, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Modal from '@mui/material/Modal';

const HoverLoader = ({ text = 'Loading...' }) => {
    return (
        <Modal
            open={true}
        >
            <Box sx={{ alignItems: 'center', justifyContent: 'center', display: 'flex', height: '100%' }} >
                <Box sx={{ p: 2, borderRadius: 2, gap: 2, textAlign: 'center', background: '#fff', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <CircularProgress color="primary" />
                    {text && <Typography color="primary">{text}</Typography>}
                </Box>
            </Box>
        </Modal>
    )
}

export { HoverLoader };