
import { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useIntl } from "react-intl";

import CustomTable from 'components/table';
import { TableEditButton, DeleteButton, TableButtonGroup } from 'components/button';
import { masterPaginationServices } from 'utils/constants';
import { getTableParams } from 'utils/global';
import { RootReducerState } from 'utils/types';
import { getMasterPaginationData } from 'redux/actions';
import PageLayout from 'layouts/PageLayout';
import Form from './Form';
import { services } from 'redux/services';
import { useToastMessage } from 'hooks';
import { categoryColumns } from 'utils/constants/tableColumn/column';

const tableInitialState = {
    pageSize: 100,
    page: 1,
    sortKey: '',
    sortType: '',
    columnFilter: [],
    searchColumnFilters: [],
}

const Index: React.FC = () => {
    const { formatMessage } = useIntl();
    const dispatch = useDispatch();
    const { toastMessage } = useToastMessage();

    const [tableState, setTableState] = useState(tableInitialState);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [drawer, setDrawer] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [categoryOptions, setCategoryOptions] = useState([]);

    const { responseData, loading } = useSelector(
        ({ masterPaginationReducer }: RootReducerState) => ({
            responseData: masterPaginationReducer[masterPaginationServices.galleryCategory].data,
            loading: masterPaginationReducer[masterPaginationServices.galleryCategory].loading,
        }),
        shallowEqual
    );

    const { rows, count } = responseData;

    useEffect(() => {
        onApiCall();
    }, [tableState]);

    function onApiCall() {
        const params = getTableParams(tableState);
        dispatch(getMasterPaginationData(masterPaginationServices.galleryCategory, params));
    }

    const columnAction = [
        {
            label: formatMessage({ id: "category" }),
            name: "category_name",
        },
        {
            label: formatMessage({ id: "action" }),
            name: "",
            options: {
                customBodyRender: (_: any, tableMeta: any) => {
                    return (
                        <TableButtonGroup>
                            <TableEditButton
                                tooltipLabel="Edit"
                                onClick={() => handleRowClick(rows[tableMeta.rowIndex])}
                            />
                            <DeleteButton
                                onDelete={() => onDeleteData(rows[tableMeta.rowIndex])}
                            />
                        </TableButtonGroup>
                    )
                }
            }
        }]

    function handleRowClick(rowData: any) {
        setSelectedRow(rowData);
        setDrawer(true);
    }

    function handleCreate() {
        setSelectedRow(null);
        setDrawer(true);
    }

    function onDeleteData(data: any) {
        const params = { id: data.id };
        setDeleteLoading(true);
        services.deleteGalleryCategory(params)
            .then((res: any) => {
                setDeleteLoading(false);
                onApiCall();
                toastMessage(formatMessage({ id: "delete-message" }));
            })
            .catch((err) => {
                setDeleteLoading(false);
                toastMessage(err?.data?.message ? err?.data?.message : formatMessage({ id: "opps-error-message" }), 'error');
            })
    }

    useEffect(() => {
        if (rows?.length > 0) {
            setCategoryOptions(rows?.map((x: any) => ({
                label: x.title,
                value: x.id
            })))
        }
    }, [rows]);

    return (
        <PageLayout title={formatMessage({ id: "gallery-category" })} onCreate={handleCreate}>
            <CustomTable
                columns={[...categoryColumns(formatMessage), ...columnAction]}
                tableData={rows}
                tableState={tableState}
                rowsCount={count}
                setTableState={setTableState}
                loading={loading || deleteLoading}
            />

            {drawer && (
                <Form
                    refreshData={onApiCall}
                    open={drawer}
                    parentOptionData={categoryOptions}
                    closeModal={() => setDrawer(false)}
                    formMode={!selectedRow ? 'create' : 'update'}
                    selectedData={selectedRow}
                    headerText={!selectedRow ? formatMessage({ id: "create-gallery-category" }) : formatMessage({ id: "update-gallery-category" })}
                />
            )}
        </PageLayout>
    )
}

export default Index;
