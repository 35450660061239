import Dashboard from '@mui/icons-material/Dashboard';
import GroupIcon from '@mui/icons-material/Group';
import { BrowseGallery, Comment, Construction, ContactMail, DesignServices, Group, Image, ImageAspectRatio, Info, LocalPostOffice, MiscellaneousServices, Pages, Settings, VerifiedUser } from '@mui/icons-material';
import AttributionIcon from '@mui/icons-material/Attribution';

import { roleData } from '../utils/constants/default';

const SidebarMenu = [
  // {
  //   title: 'dashboard',
  //   path: '/admin/dashboard',
  //   icon: <Dashboard />,
  //   role: [roleData.superadmin, roleData.admin]
  // },
  {
    title: 'Banners',
    path: '/admin/banners',
    icon: <Image />,
    role: [roleData.superadmin, roleData.admin]
  },
  {
    title: 'Service',
    path: '/admin/service',
    icon: <DesignServices />,
    role: [roleData.superadmin, roleData.admin],
    children: [
      {
        title: ' Service Category',
        path: '/admin/service/categories',
        icon: <GroupIcon />,
        role: [roleData.superadmin, roleData.admin]
      },
      {
        title: 'Service List',
        path: '/admin/service/list',
        icon: <DesignServices />,
        role: [roleData.superadmin, roleData.admin]
      },
    ]
  },
  {
    title: 'Team',
    path: '/admin/teams',
    icon: <Group />,
    role: [roleData.superadmin, roleData.admin]
  },
  {
    title: 'Projects',
    path: '/admin/project',
    icon: <Construction />,
    role: [roleData.superadmin, roleData.admin],
    children: [
      {
        title: ' Project Category',
        path: '/admin/project/categories',
        icon: <GroupIcon />,
        role: [roleData.superadmin, roleData.admin]
      },
      {
        title: 'Project List',
        path: '/admin/project/list',
        icon: <Construction />,
        role: [roleData.superadmin, roleData.admin]
      },
    ]
  },
  {
    title: 'Client',
    path: '/admin/clients',
    icon: <VerifiedUser />,
    role: [roleData.superadmin, roleData.admin]
  },
  {
    title: 'Page',
    path: '/admin/pages',
    icon: <Pages />,
    role: [roleData.superadmin, roleData.admin]
  },
  // {
  //   title: 'Accounting',
  //   path: '/admin/accounting',
  //   icon: <AccountBalance />,
  //   role: [roleData.superadmin,roleData.admin]
  // },
  {
    title: 'Gallery',
    path: '/admin/gallery',
    icon: <ImageAspectRatio />,
    role: [roleData.superadmin, roleData.admin],
    children: [
      {
        title: ' Gallery Category',
        path: '/admin/gallery/categories',
        icon: <BrowseGallery />,
        role: [roleData.superadmin, roleData.admin]
      },
      {
        title: 'Gallery List',
        path: '/admin/gallery/list',
        icon: <ImageAspectRatio />,
        role: [roleData.superadmin, roleData.admin]
      },
    ]
  },
  {
    title: 'About',
    path: '/admin/about-us',
    icon: <LocalPostOffice />,
    role: [roleData.superadmin, roleData.admin]
  },
  {
    title: 'Testimonial',
    path: '/admin/testimonials',
    icon: <Comment />,
    role: [roleData.superadmin, roleData.admin]
  },
  {
    title: 'Enquiries',
    path: '/admin/enquiries',
    icon: <ContactMail />,
    role: [roleData.superadmin, roleData.admin]
  },
  {
    title: 'User',
    path: '/admin/users',
    icon: <AttributionIcon />,
    role: [roleData.superadmin, roleData.admin]
  },
 
  {
    title: 'Master',
    path: '/admin/master',
    icon: <Settings />,
    role: [roleData.superadmin, roleData.admin],
    children: [
      {
        title: 'Role',
        path: '/admin/master/roles',
        icon: <GroupIcon />,
        role: [roleData.superadmin, roleData.admin]
      },
      // {
      //   title: 'Miscellaneous',
      //   path: '/admin/master/miscellaneous',
      //   icon: <MiscellaneousServices />,
      //   role: [roleData.superadmin, roleData.admin]
      // },
    ]
  },
];

export default SidebarMenu;
