import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Grid } from '@mui/material';
import { CustomCheckBox, CustomDatePicker, CustomFileUpload, CustomSelect, CustomTextArea, CustomTextBox, CustomTextEditor } from 'components/forms';
import { CustomDrawer, HoverLoader } from 'components';
import { validationRule } from 'utils/global';
import { useToastMessage } from 'hooks';
import { services } from 'redux/services';
import { FileAcceptData, fileValidate } from 'utils/constants';

interface Props {
    open: boolean;
    closeModal: () => void;
    formMode: 'create' | 'update';
    selectedData: any;
    headerText: string;
    categoryOptions: Array<any>,
    refreshData: () => void;
}

const Form = (props: Props) => {
    const { closeModal, open, formMode, selectedData, headerText, categoryOptions, refreshData } = props;
    const { toastMessage } = useToastMessage();
    const { handleSubmit, formState: { errors }, control, watch, setValue, register, clearErrors, reset } = useForm<any>({
        mode: 'all',
        defaultValues: { is_active: 1, order: 0 }
    });
    const { formatMessage } = useIntl();
    const [loading, setLoading] = useState(false);
    const [fileImage, setFileImage] = useState({
        file: '',
    })

    function onSubmit(data: any) {
        setLoading(true);
        data.file = data?.file?.[0];
        data.category_id = data?.category_id?.value;
        data.is_active = data?.is_active ? 1 : 0;
        data.is_featured = data?.is_featured ? 1 : 0;
        if (formMode === 'update') data.id = selectedData?.id;
        const apiFunc = formMode === 'create' ? 'createProject' : 'updateProject';
        const message = formMode === 'create' ? 'created-message' : 'updated-message';
        services[apiFunc](data)
            .then((res: any) => {
                setLoading(false);
                toastMessage(formatMessage({ id: message }, { title: "service" }));
                closeModal();
                refreshData();
            })
            .catch((err: any) => {
                setLoading(false);
                toastMessage(err?.data?.message, 'error');
            })
    }

    //RESET DEFAULTVALUE IN UPDATE MODE
    useEffect(() => {
        setLoading(true);
        function resetData() {
            reset({
                category_id: categoryOptions?.find(x => x.value == selectedData.category_id),
                title: selectedData.title,
                sub_title: selectedData.sub_title,
                client: selectedData.client,
                budget: selectedData.budget,
                year: selectedData.year,
                address: selectedData.address,
                area: selectedData.area,
                description: selectedData.description,
                short_description: selectedData.short_description,
                meta_title: selectedData.meta_title,
                meta_description: selectedData.meta_description,
                is_active: selectedData.is_active,
                is_featured: selectedData.is_featured,
                order: selectedData.order ?? 0,
                file: null,
            });
            setFileImage({ file: selectedData.file_path_url })
        }

        if (selectedData) {
            setTimeout(() => {
                resetData();
                setLoading(false);
            }, 1000);
        } else {
            setLoading(false);
        }

    }, [formMode === 'update', categoryOptions]);

    return (
        <CustomDrawer
            formMode={formMode}
            open={open}
            onCancel={closeModal}
            onSubmit={handleSubmit(onSubmit)}
            title={headerText}
            size='xl'
            anchor='bottom'
        >
            <Grid container spacing={2}>
                {loading && <HoverLoader />}
                <Grid item xs={12}>
                    <CustomSelect
                        options={categoryOptions}
                        label={formatMessage({ id: "category" })}
                        name="category_id"
                        control={control}
                        error={errors?.category_id}
                        rules={validationRule.textbox({ required: true })}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CustomTextBox
                        label={formatMessage({ id: "title" })}
                        name="title"
                        control={control}
                        error={errors?.title}
                        rules={validationRule.textbox({ required: true })}
                    />
                </Grid>
                {/* <Grid item xs={12}>
                    <CustomTextBox
                        label={formatMessage({ id: "sub-title" })}
                        name="sub_title"
                        control={control}
                        error={errors?.sub_title}
                        rules={validationRule.textbox({ required: true })}
                    />
                </Grid> */}
                {/* <Grid item xs={12}>
                    <CustomTextArea
                        formLabel={formatMessage({ id: "short-description" })}
                        name="short_description"
                        control={control}
                        minRows={3}
                    />
                </Grid> */}
                <Grid item lg={4} xs={12}>
                    <CustomTextBox
                        label={formatMessage({ id: "client" })}
                        name="client"
                        control={control}
                    />
                </Grid>
                <Grid item lg={4} xs={12}>
                    <CustomTextBox
                        label={formatMessage({ id: "budget" })}
                        name="budget"
                        control={control}
                    />
                </Grid>
                <Grid item lg={4} xs={12}>
                    <CustomTextBox
                        label={formatMessage({ id: "area" })}
                        name="area"
                        control={control}
                    />
                </Grid>
                <Grid item lg={4} xs={12}>
                    <CustomDatePicker
                        views={['year']}
                        label={formatMessage({ id: "year" })}
                        name="year"
                        control={control}
                    />
                </Grid>
                <Grid item lg={4} xs={12}>
                    <CustomTextBox
                        label={formatMessage({ id: "address" })}
                        name="address"
                        control={control}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CustomTextEditor
                        name="description"
                        defaultValue={watch('description')}
                        error={errors?.description}
                        onChange={(editor: any) => setValue('description', editor)}
                    />
                    {/* <CustomTextArea
                        formLabel={formatMessage({ id: "description" })}
                        name="description"
                        control={control}
                        minRows={6}
                    /> */}
                </Grid>
                <Grid item lg={4} xs={12}>
                    <CustomFileUpload
                        accept={FileAcceptData.IMAGES}
                        label={formatMessage({ id: "image" })}
                        error={errors?.file}
                        {...register('file', {
                            validate: (value: any) => value?.length > 0 ? fileValidate(value?.[0]) : true,
                        })}
                        defaultFileUrl={fileImage.file}
                        onClear={() => {
                            setValue(`file`, null);
                            clearErrors(`file`);
                        }}
                        onChange={(file: any) => setValue('file', file)}
                    />
                </Grid>
                <Grid item xs={12} />
                <Grid item lg={4} md={6} sm={12} xs={12}>
                    <CustomCheckBox
                        name="is_active"
                        label={formatMessage({ id: "is-active" })}
                        control={control}
                        isDefaultChecked
                    />
                </Grid>
                <Grid item lg={4} md={6} sm={12} xs={12}>
                    <CustomCheckBox
                        name="is_featured"
                        label={formatMessage({ id: "is-featured" })}
                        control={control}
                        isDefaultChecked
                    />
                </Grid>
                <Grid item lg={4} md={6} sm={12} xs={12}>
                    <CustomTextBox
                        label={formatMessage({ id: "order" })}
                        name="order"
                        type='number'
                        control={control}
                        error={errors?.order}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CustomTextBox
                        label={formatMessage({ id: "meta-title" })}
                        name="meta_title"
                        control={control}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CustomTextArea
                        formLabel={formatMessage({ id: "meta-description" })}
                        name="meta_description"
                        control={control}
                        minRows={6}
                    />
                </Grid>
            </Grid>
        </CustomDrawer>
    )
}

export default Form;