import {
    AUTH_START,
    AUTH_SUCCESS,
    AUTH_FAIL,
    AUTH_RESET,
    RESET_TOKEN,
} from "../actionTypes";
import { AnyAction, Dispatch } from 'redux';
import Axios from 'axios';
import { API_ENDPOINTS } from "redux/apiEndPoints";

export const login = (payload: any, toastMessage: any): any => {
    return (dispatch: Dispatch<AnyAction>) => {
        dispatch({ type: AUTH_START });
        Axios.post(`${API_ENDPOINTS.LOGIN}`, payload)
            .then((res: any) => {
                const { data, status } = res;
                if (status === 200) {
                    dispatch({ type: AUTH_SUCCESS, payload: data?.result });
                    // dispatch(getAccount(data?.result?.user?.uuid,data?.result?.token, toastMessage));
                } else {
                    toastMessage("Something went wrong. Please try again", 'error');
                    dispatch({ type: AUTH_FAIL });
                }
            })
            .catch((err) => {
                dispatch({ type: AUTH_FAIL });
                toastMessage(JSON.stringify(err?.response?.data.message),'error');
            });
    };
};

export function authReset() {
    return {
        type: AUTH_RESET
    }
}

export function resetToken(payload: any) {
    return {
        type: RESET_TOKEN,
        payload
    }
}