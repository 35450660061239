import React, { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { Box, Button, Grid, IconButton, Stack } from '@mui/material';
import { Container } from '@mui/system';
import { Add, Delete, Save } from '@mui/icons-material';

import { CustomFileUpload, CustomTextArea, CustomTextBox } from 'components/forms';
import { HoverLoader } from 'components';
import { useToastMessage } from 'hooks';
import SectionLayout from 'layouts/SectionLayout';
import { services } from 'redux/services';
import { FileAcceptData, fileValidate } from 'utils/constants';
import { validationRule } from 'utils/global';

const fieldInitialState = {
    label: '',
    icon: '',
    value: '',
}

const Form: React.FC = () => {
    const { toastMessage } = useToastMessage();
    const { handleSubmit, formState: { errors }, register, clearErrors, setValue, control, reset } = useForm<any>({
        mode: 'all',
        defaultValues: {
            social_link: [fieldInitialState],
            other_phone: [fieldInitialState],
            other_mail: [fieldInitialState]
        }
    });
    const { formatMessage } = useIntl();
    const [loading, setLoading] = useState(false);
    const [fileImage, setFileImage] = useState({
        header_logo: '',
        footer_logo: '',
    })

    const { fields: sfields, append: sappend, remove: sremove } = useFieldArray({
        control,
        name: "social_link",
    });

    const { fields: pfields, append: pappend, remove: premove } = useFieldArray({
        control,
        name: "other_phone",
    });

    const { fields: mfields, append: mappend, remove: mremove } = useFieldArray({
        control,
        name: "other_mail",
    });

    function onSubmit(data: any) {
        setLoading(true);
        data.header_logo = data?.header_logo?.[0];
        data.footer_logo = data?.footer_logo?.[0];
        const message = 'updated-message';
        services.updateAbout(data)
            .then((res: any) => {
                toastMessage(formatMessage({ id: message }, { title: formatMessage({ id: 'about' }) }));
            })
            .catch((err: any) => {
                toastMessage(err?.data?.message, 'error');
            }).finally(() => setLoading(false))
    }

    // RESET DEFAULTVALUE IN UPDATE MODE
    useEffect(() => {
        services.getAbout()
            .then((res: any) => {
                const response = res.result;
                if (response) {
                    reset({
                        title: response.title,
                        introduction: response.introduction,
                        address: response.address,
                        office_time: response.office_time,
                        primary_phone: response.primary_phone,
                        primary_mail: response.primary_mail,
                        map: response.map,
                        meta_title: response.meta_title,
                        meta_description: response.meta_description,
                        social_link: response.social_link ?? [fieldInitialState],
                        other_phone: response.other_phone ?? [fieldInitialState],
                        other_mail: response.other_mail ?? [fieldInitialState],
                        footer_text: response.footer_text,
                        header_logo: null,
                        footer_logo: null,
                    })
                    setFileImage({
                        header_logo: response?.header_logo_url,
                        footer_logo: response?.footer_logo_url
                    })
                }
            })
            .catch((err: any) => {
                toastMessage(err?.data?.message, 'error');
            })
            .finally(() => setLoading(false))
    }, []);

    return (
        <Container maxWidth="xl">
            {loading && <HoverLoader />}
            <SectionLayout title={formatMessage({ id: "company-profile" })}>
                <Grid container spacing={2}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "title" })}
                            name="title"
                            control={control}
                            error={errors?.title}
                            rules={validationRule.textbox({ required: true })}
                        />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "office-time" })}
                            name="office_time"
                            control={control}
                        />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "address" })}
                            name="address"
                            control={control}
                            error={errors?.address}
                            rules={validationRule.textbox({ required: true })}
                        />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "phone" })}
                            name="primary_phone"
                            type={'number'}
                            control={control}
                            error={errors?.primary_phone}
                            rules={validationRule.textbox({ required: true })}
                        />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "mail" })}
                            name="primary_mail"
                            type='email'
                            control={control}
                            error={errors?.primary_mail}
                            rules={validationRule.textbox({ required: true, type: 'email' })}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <CustomTextArea
                            formLabel={formatMessage({ id: "introduction" })}
                            name="introduction"
                            control={control}
                            minRows={6}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "footer-text" })}
                            name="footer_text"
                            control={control}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "map" })}
                            name="map"
                            control={control}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {sfields.map((field, index) => (
                            <Box key={field.id} sx={{ display: 'flex', gap: 1, marginY: 1 }}>
                                <CustomTextBox
                                    // label={formatMessage({ id: "name" })}
                                    name={`social_link.${index}.label`}
                                    control={control}
                                    placeholder='Example : Facebook, Instagram, Tiktok ....'
                                />
                                <CustomTextBox
                                    // label={formatMessage({ id: "icon" })}
                                    name={`social_link.${index}.icon`}
                                    control={control}
                                    placeholder='Example : fa fa-facebook'
                                />
                                <CustomTextBox
                                    // label={formatMessage({ id: "url" })}
                                    name={`social_link.${index}.value`}
                                    control={control}
                                    placeholder='Example : www.facebook.com/abcd'
                                />
                                {sfields?.length > 1 &&
                                    <IconButton onClick={() => sremove(index)}>
                                        <Delete color='error' />
                                    </IconButton>
                                }
                            </Box>
                        ))}
                        <Button
                            startIcon={<Add />}
                            variant='contained'
                            onClick={() => sappend(fieldInitialState)}
                        >
                            Add Social Link
                        </Button>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        {pfields.map((field, index) => (
                            <Box key={field.id} sx={{ display: 'flex', gap: 1, marginY: 1 }}>
                                <CustomTextBox
                                    // label={formatMessage({ id: "name" })}
                                    name={`other_phone.${index}.label`}
                                    control={control}
                                    placeholder='Example : Customer Care'
                                />
                                <CustomTextBox
                                    // label={formatMessage({ id: "value" })}
                                    name={`other_phone.${index}.value`}
                                    control={control}
                                    placeholder='Example : 9813562111'
                                />
                                {pfields?.length > 1 &&
                                    <IconButton onClick={() => premove(index)}>
                                        <Delete color='error' />
                                    </IconButton>
                                }
                            </Box>
                        ))}
                        <Button
                            startIcon={<Add />}
                            variant='contained'
                            onClick={() => pappend(fieldInitialState)}
                        >
                            Add Phone
                        </Button>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        {mfields.map((field, index) => (
                            <Box key={field.id} sx={{ display: 'flex', gap: 1, marginY: 1 }}>
                                <CustomTextBox
                                    // label={formatMessage({ id: "name" })}
                                    name={`other_mail.${index}.label`}
                                    control={control}
                                    placeholder='Example : Marking, Support...'
                                />
                                <CustomTextBox
                                    // label={formatMessage({ id: "value" })}
                                    name={`other_mail.${index}.value`}
                                    control={control}
                                    placeholder='Example : marking@abcde.com'
                                />
                                {mfields?.length > 1 &&
                                    <IconButton onClick={() => mremove(index)}>
                                        <Delete color='error' />
                                    </IconButton>
                                }
                            </Box>
                        ))}
                        <Button
                            startIcon={<Add />}
                            variant='contained'
                            onClick={() => mappend(fieldInitialState)}
                        >
                            Add Mail
                        </Button>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <CustomFileUpload
                            accept={FileAcceptData.IMAGES}
                            {...register('header_logo', {
                                validate: (value: any) => value?.length > 0 ? fileValidate(value?.[0]) : true
                            })}
                            label={formatMessage({ id: "header-logo" })}
                            error={errors?.header_logo}
                            defaultFileUrl={fileImage.header_logo}
                            defaultFileName={''}
                            onClear={() => {
                                setValue(`header_logo`, null);
                                clearErrors(`header_logo`);
                            }}
                            onChange={(file: any) => setValue('header_logo', file)}
                        />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <CustomFileUpload
                            accept={FileAcceptData.IMAGES}
                            {...register('footer_logo', {
                                validate: (value: any) => value?.length > 0 ? fileValidate(value?.[0]) : true
                            })}
                            label={formatMessage({ id: "footer-logo" })}
                            error={errors?.footer_logo}
                            defaultFileUrl={fileImage.footer_logo}
                            defaultFileName={''}
                            onClear={() => {
                                setValue(`footer_logo`, null);
                                clearErrors(`footer_logo`);
                            }}
                            onChange={(file: any) => setValue('footer_logo', file)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "meta-title" })}
                            name="meta_title"
                            control={control}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CustomTextArea
                            formLabel={formatMessage({ id: "meta-description" })}
                            name="meta_description"
                            control={control}
                            minRows={6}
                        />
                    </Grid>
                </Grid>
                <Stack direction="row" alignItems="center" justifyContent="flex-end" my={5}>
                    <Button variant="contained" type='submit'
                        onClick={handleSubmit(onSubmit)}
                        startIcon={<Save />}>
                        Submit
                    </Button>
                </Stack>
            </SectionLayout>
        </Container>
    )
}

export default Form;