import { services } from "redux/services";

const check = <T extends string>(
  collection: Record<T, keyof typeof services>
) => collection;

export const masterPaginationServices = check({
  projectCategory: "getAllProjectCategory",
  project: "getAllProject",
  serviceCategory: "getAllServiceCategory",
  service: "getAllService",
  client: "getAllClient",
  team: "getAllTeam",
  role: "getAllRole",
  banner: "getAllBanner",
  user: "getAllUser",
  expense: "getAllExpense",
  miscellaneous: "getAllMiscellaneous",
  gallery: "getAllGallery",
  galleryCategory: "getAllGalleryCategory",
  testimonial: "getAllTestimonial",
  enquiry: "getAllEnqiryList",
  page: "getAllPage",
});
