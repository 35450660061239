import DashboardLayout from "layouts/dashboard";
import {
  Role,
  Banner,
  Dashboard,
  Accounting,
  Miscellaneous,
  Service,
  ServiceCategory,
  Project,
  ProjectCategory,
  Client,
  Team,
  User,
  About,
  GalleryCategory,
  Gallery,
  Page,
  Testimonial,
  Enquiry,
} from "pages";
import Login from "pages/Login";
import NotFound from "pages/Page404";
import { Navigate, useRoutes } from "react-router-dom";
import { roleData } from "utils/constants";
import PrivateRoute from "./PrivateRoute";

export default function Router() {
  return useRoutes([
    {
      path: "/admin",
      element: (
        <PrivateRoute roles={[roleData.superadmin, roleData.admin]}>
          <DashboardLayout />
        </PrivateRoute>
      ),
      children: [
        {
          path: "dashboard",
          element: (
            <PrivateRoute roles={[roleData.superadmin, roleData.admin]}>
              <Dashboard />
            </PrivateRoute>
          ),
        },
        {
          path: "master/roles",
          element: (
            <PrivateRoute roles={[roleData.superadmin, roleData.admin]}>
              <Role />
            </PrivateRoute>
          ),
        },
        {
          path: "banners",
          element: (
            <PrivateRoute roles={[roleData.superadmin, roleData.admin]}>
              <Banner />
            </PrivateRoute>
          ),
        },
        {
          path: "pages",
          element: (
            <PrivateRoute roles={[roleData.superadmin, roleData.admin]}>
              <Page />
            </PrivateRoute>
          ),
        },
        {
          path: "service/list",
          element: (
            <PrivateRoute roles={[roleData.superadmin, roleData.admin]}>
              <Service />
            </PrivateRoute>
          ),
        },
        {
          path: "service/categories",
          element: (
            <PrivateRoute roles={[roleData.superadmin, roleData.admin]}>
              <ServiceCategory />
            </PrivateRoute>
          ),
        },
        {
          path: "project/list",
          element: (
            <PrivateRoute roles={[roleData.superadmin, roleData.admin]}>
              <Project />
            </PrivateRoute>
          ),
        },
        {
          path: "project/categories",
          element: (
            <PrivateRoute roles={[roleData.superadmin, roleData.admin]}>
              <ProjectCategory />
            </PrivateRoute>
          ),
        },
        {
          path: "master/miscellaneous",
          element: (
            <PrivateRoute roles={[roleData.superadmin, roleData.admin]}>
              <Miscellaneous />
            </PrivateRoute>
          ),
        },
        {
          path: "clients",
          element: (
            <PrivateRoute roles={[roleData.superadmin, roleData.admin]}>
              <Client />
            </PrivateRoute>
          ),
        },
        {
          path: "teams",
          element: (
            <PrivateRoute roles={[roleData.superadmin, roleData.admin]}>
              <Team />
            </PrivateRoute>
          ),
        },
        {
          path: "accounting",
          element: (
            <PrivateRoute roles={[roleData.superadmin, roleData.admin]}>
              <Accounting />
            </PrivateRoute>
          ),
        },
        {
          path: "users",
          element: (
            <PrivateRoute roles={[roleData.superadmin, roleData.admin]}>
              <User />
            </PrivateRoute>
          ),
        },
        {
          path: "about-us",
          element: (
            <PrivateRoute roles={[roleData.superadmin, roleData.admin]}>
              <About />
            </PrivateRoute>
          ),
        },
        {
          path: "gallery/list",
          element: (
            <PrivateRoute roles={[roleData.superadmin, roleData.admin]}>
              <Gallery />
            </PrivateRoute>
          ),
        },
        {
          path: "gallery/categories",
          element: (
            <PrivateRoute roles={[roleData.superadmin, roleData.admin]}>
              <GalleryCategory />
            </PrivateRoute>
          ),
        },
        {
          path: "testimonials",
          element: (
            <PrivateRoute roles={[roleData.superadmin, roleData.admin]}>
              <Testimonial />
            </PrivateRoute>
          ),
        },
        {
          path: "enquiries",
          element: (
            <PrivateRoute roles={[roleData.superadmin, roleData.admin]}>
              <Enquiry />
            </PrivateRoute>
          ),
        },
      ],
    },
    { path: "/", element: <Login /> },
    { path: "404", element: <NotFound /> },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
