import dayjs from "dayjs";
import Chip from "@mui/material/Chip";

import { BooleanIcon } from "components";
import { GridCellExpand } from "components/table/GridCellExpand";
import { filterTypes, genderList } from "../default";
import { constants } from "utils/constants";
import { Clear, Flag } from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";
import { Typography } from "@mui/material";
import UserPhoto from 'assets/images/user-icon.jpeg';

export const dateOptions = {
  hideGlobalSearchFilter: true,
  options: {
    customBodyRender: (value: string) => {
      return <span>{value ? dayjs(value).format(constants.df) : ""}</span>;
    },
  },
  type: filterTypes.date,
};

export const dateTimeOptions = {
  hideGlobalSearchFilter: true,
  options: {
    customBodyRender: (value: string) => {
      return <span>{value ? dayjs(value).format(constants.dtf) : ""}</span>;
    },
  },
  type: filterTypes.date,
};

export const timeOptions = {
  options: {
    customBodyRender: (value: string) => {
      return (
        <span>{value ? dayjs(value, "hh:mm:ss").format("hh:mm A") : ""}</span>
      );
    },
  },
  hideGlobalSearchFilter: true,
  disableFilter: true,
};

export const avatarOptions = {
  options: {
    customBodyRender: (value: string) => {
      return <img
        src={value ?? UserPhoto} alt={'Photo'}
        style={{ height: 50 }}
        onError={(e:any) => {
          e.target.src = UserPhoto;
        }}
      />;
    },
  },
  hideGlobalSearchFilter: true,
  disableFilter: true,
};

export const labelOption = () => ({
  options: {
    customBodyRender: (value: string) => (
      <Typography variant="subtitle2">{value}</Typography>
    ),
  },
});

export const genderOptions = {
  name: "genderName",
  primaryColumnName: "Gender.Name",
  hideGlobalSearchFilter: true,
  type: filterTypes.select,
  selectOptions: [
    { label: "Female", value: "female" },
    { label: "Male", value: "male" },
  ],
};

export const genderApplicabilityOptions = {
  hideGlobalSearchFilter: true,
  type: filterTypes.select,
  selectOptions: genderList,
  options: {
    customBodyRender: (value: string) => {
      const object = genderList.find((item: any) => item.value == value);
      return <span>{object?.label}</span>;
    },
  },
};

export const BooleanIconOption = {
  options: {
    customBodyRender: (value: boolean) => <BooleanIcon value={value} />,
  },
  hideGlobalSearchFilter: true,
  type: filterTypes.boolean,
  selectOptions: [
    { label: "Yes", value: "true" },
    { label: "No", value: "false" },
  ],
};

export const numberOptions = {
  type: filterTypes.number,
  hideGlobalSearchFilter: true,
};

export const ChipColumnOptions = (
  optionsList: { label: string; value: string; color: string; Icon?: any }[]
) => ({
  options: {
    customBodyRender: (value: string | number) => {
      let selectedList = optionsList.find((list) => list.value == value);
      let chipColor = selectedList?.color;
      let icon = selectedList?.Icon ? <selectedList.Icon /> : undefined;

      return (
        <Chip
          size="small"
          // variant="filled"
          // color="error"
          sx={{ 
            color: chipColor, 
            // background:'#FFF',
            border: `1px solid ${chipColor}` 
          }}
          icon={icon}
          label={selectedList?.label}
        />
      );
    },
  },
  hideGlobalSearchFilter: true,
  type: filterTypes.select,
  selectOptions: optionsList.map((list) => ({
    label: list.label,
    value: list.value,
  })),
});

export const FlagColumnOptions = (
  optionsList: { label: string; value: string; color: string; Icon?: any }[]
) => ({
  options: {
    customBodyRender: (value: number) => {
      let selectedList = optionsList.find((list) => +list.value === value);
      let chipColor = "#ff0606";
      let icon = selectedList?.Icon ? (
        <selectedList.Icon />
      ) : (
        <Clear
          color="inherit"
          style={{ color: chipColor, borderColor: chipColor }}
        />
      );

      return value === 1 ? (
        <Chip
          size="small"
          variant="outlined"
          sx={{ color: chipColor, borderColor: chipColor }}
          icon={icon}
          label="Flag"
        />
      ) : (
        <Chip
          size="small"
          variant="outlined"
          sx={{ color: "green", borderColor: "green" }}
          icon={<CheckIcon sx={{ color: "green" }} />}
          label="Clean"
        />
      );
    },
  },
  hideGlobalSearchFilter: true,
  type: filterTypes.select,
  selectOptions: optionsList.map((list) => ({
    label: list.label,
    value: list.value,
  })),
});

export const cellExpandOption = (width: number = 130) => ({
  options: {
    customBodyRender: (value: string) => {
      return <GridCellExpand value={value} width={width} />;
    },
  },
});

export const booleanOptions = {
  type: filterTypes.boolean,
  hideGlobalSearchFilter: true,
  selectOptions: [
    { label: "Yes", value: 0 },
    { label: "No", value: 1 },
  ],
};

export const priceOption = {
  options: {
    customBodyRender: (value: string) => (
      <Typography variant="body2">Rs {value}</Typography>
    ),
  },
};
