import { BASE_URI } from "utils/axios";

export const API_ENDPOINTS = {
	LOGIN: `${BASE_URI}/login`,
	GET_ACCOUNT: `${BASE_URI}/get-account`,
	SELECT_ACCOUNT: `${BASE_URI}/select-account`,
	LOGOUT: `${BASE_URI}/logout`,
	DASHBOARD: `${BASE_URI}/dashboard`,

	LIST_BANNER: `${BASE_URI}/banners/list`,
	GET_BANNER_BY_ID: `${BASE_URI}/banners/get`,
	CREATE_BANNER: `${BASE_URI}/banners/save`,
	UPDATE_BANNER: `${BASE_URI}/banners/update`,
	DELETE_BANNER: `${BASE_URI}/banners/delete`,

	LIST_SERVICE: `${BASE_URI}/services/list`,
	GET_SERVICE_BY_ID: `${BASE_URI}/services/get`,
	CREATE_SERVICE: `${BASE_URI}/services/save`,
	UPDATE_SERVICE: `${BASE_URI}/services/update`,
	DELETE_SERVICE: `${BASE_URI}/services/delete`,

	LIST_SERVICE_CATEGORY: `${BASE_URI}/service-categories/list`,
	GET_SERVICE_CATEGORY_BY_ID: `${BASE_URI}/service-categories/get`,
	CREATE_SERVICE_CATEGORY: `${BASE_URI}/service-categories/save`,
	UPDATE_SERVICE_CATEGORY: `${BASE_URI}/service-categories/update`,
	DELETE_SERVICE_CATEGORY: `${BASE_URI}/service-categories/delete`,

	LIST_PROJECT: `${BASE_URI}/projects/list`,
	GET_PROJECT_BY_ID: `${BASE_URI}/projects/get`,
	CREATE_PROJECT: `${BASE_URI}/projects/save`,
	UPDATE_PROJECT: `${BASE_URI}/projects/update`,
	DELETE_PROJECT: `${BASE_URI}/projects/delete`,

	LIST_PROJECT_CATEGORY: `${BASE_URI}/project-categories/list`,
	GET_PROJECT_CATEGORY_BY_ID: `${BASE_URI}/project-categories/get`,
	CREATE_PROJECT_CATEGORY: `${BASE_URI}/project-categories/save`,
	UPDATE_PROJECT_CATEGORY: `${BASE_URI}/project-categories/update`,
	DELETE_PROJECT_CATEGORY: `${BASE_URI}/project-categories/delete`,

	CREATE_PROJECT_IMAGE: `${BASE_URI}/project-images/save`,
	LIST_PROJECT_IMAGE: `${BASE_URI}/project-images/list`,
	DELETE_PROJECT_IMAGE: `${BASE_URI}/project-images/delete`,

	LIST_CLIENT: `${BASE_URI}/clients/list`,
	GET_CLIENT_BY_ID: `${BASE_URI}/clients/get`,
	CREATE_CLIENT: `${BASE_URI}/clients/save`,
	UPDATE_CLIENT: `${BASE_URI}/clients/update`,
	DELETE_CLIENT: `${BASE_URI}/clients/delete`,

	LIST_TEAM: `${BASE_URI}/teams/list`,
	GET_TEAM_BY_ID: `${BASE_URI}/teams/get`,
	CREATE_TEAM: `${BASE_URI}/teams/save`,
	UPDATE_TEAM: `${BASE_URI}/teams/update`,
	DELETE_TEAM: `${BASE_URI}/teams/delete`,

	LIST_USER: `${BASE_URI}/account-users/list`,
	GET_USER_BY_UUID: `${BASE_URI}/account-users/get`,
	CREATE_USER: `${BASE_URI}/account-users/save`,
	UPDATE_USER: `${BASE_URI}/account-users/update`,
	UPDATE_USER_FLAG: `${BASE_URI}/account-users/update-flag`,
	DELETE_USER: `${BASE_URI}/account-users/delete`,
	USER_LOOKUP: `${BASE_URI}/account-users/lookup`,

	LIST_EXPENSE: `${BASE_URI}/expense-items/list`,
	CREATE_EXPENSE: `${BASE_URI}/expense-items/save`,
	DELETE_EXPENSE: `${BASE_URI}/expense-items/delete`,

	GENERATE_REPORT: `${BASE_URI}/report/generate`,

	GET_BILLING: `${BASE_URI}/subscription/billing`,
	SAVE_BILLING: `${BASE_URI}/subscription/billing-payment`,

	LIST_GALLERY: `${BASE_URI}/galleries/list`,
	GET_GALLERY_BY_ID: `${BASE_URI}/galleries/get`,
	CREATE_GALLERY: `${BASE_URI}/galleries/save`,
	UPDATE_GALLERY: `${BASE_URI}/galleries/update`,
	DELETE_GALLERY: `${BASE_URI}/galleries/delete`,

	LIST_GALLERY_CATEGORY: `${BASE_URI}/gallery-categories/list`,
	GET_GALLERY_CATEGORY_BY_ID: `${BASE_URI}/gallery-categories/get`,
	CREATE_GALLERY_CATEGORY: `${BASE_URI}/gallery-categories/save`,
	UPDATE_GALLERY_CATEGORY: `${BASE_URI}/gallery-categories/update`,
	DELETE_GALLERY_CATEGORY: `${BASE_URI}/gallery-categories/delete`,

	CREATE_GALLERY_IMAGE: `${BASE_URI}/gallery-images/save`,
	LIST_GALLERY_IMAGE: `${BASE_URI}/gallery-images/list`,
	DELETE_GALLERY_IMAGE: `${BASE_URI}/gallery-images/delete`,

	LIST_PAGE: `${BASE_URI}/pages/list`,
	GET_PAGE_BY_ID: `${BASE_URI}/pages/get`,
	CREATE_PAGE: `${BASE_URI}/pages/save`,
	UPDATE_PAGE: `${BASE_URI}/pages/update`,
	DELETE_PAGE: `${BASE_URI}/pages/delete`,

	GET_ABOUT: `${BASE_URI}/abouts/get`,
	UPDATE_ABOUT: `${BASE_URI}/abouts/update`,

	LIST_TESTIMONIAL: `${BASE_URI}/testimonials/list`,
	GET_TESTIMONIAL_BY_ID: `${BASE_URI}/testimonials/get`,
	CREATE_TESTIMONIAL: `${BASE_URI}/testimonials/save`,
	UPDATE_TESTIMONIAL: `${BASE_URI}/testimonials/update`,
	DELETE_TESTIMONIAL: `${BASE_URI}/testimonials/delete`,

	ENQUIRY_LIST: `${BASE_URI}/enquiry-list`,
};
