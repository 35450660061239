import AccessDenied from 'pages/AccessDenied';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { findCommonItems } from 'utils/global';
import { RootReducerState } from 'utils/types';

export enum ROLE {
    Admin = 'superadmin',
    User = 'User',
  }

const PrivateRoute = ({ children, roles }: { children: JSX.Element ,roles: Array<string>;}) => {
  let location = useLocation();

  const { data } = useSelector(({ auth }: RootReducerState) => ({ data: auth.data,loading: auth.loading  }));

  const userHasRequiredRole = findCommonItems(roles,data.role);

  if (!data?.token) {
    return <Navigate to="/login" state={{ from: location }} />;
  }


  if (data?.token && !userHasRequiredRole) {
    return <AccessDenied />; // build your won access denied page (sth like 404)
  }


  return children;
};

export default PrivateRoute;