import { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Box, Button, Grid, IconButton } from '@mui/material';

import { CustomCheckBox, CustomFileUpload, CustomSelect, CustomTextArea, CustomTextBox } from 'components/forms';
import { CustomDrawer, HoverLoader } from 'components';
import { validationRule } from 'utils/global';
import { useToastMessage } from 'hooks';
import { services } from 'redux/services';
import { FileAcceptData, departmentOptions, fileValidate } from 'utils/constants';
import { Add, Delete } from '@mui/icons-material';

interface Props {
    open: boolean;
    closeModal: () => void;
    formMode: 'create' | 'update';
    selectedData: any;
    headerText: string;
    refreshData: () => void;
}

const buttonInitialState = {
    code: '',
    url: '',
}

const Form = (props: Props) => {
    const { closeModal, open, formMode, selectedData, headerText, refreshData } = props;
    const { toastMessage } = useToastMessage();
    const { handleSubmit, formState: { errors }, control, setValue, register, clearErrors, reset } = useForm<any>({
        mode: 'all',
        defaultValues: { is_active: 1, order: 0, social_profile: [buttonInitialState] }
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: "social_profile",
    });

    const { formatMessage } = useIntl();
    const [loading, setLoading] = useState(false);
    const [fileImage, setFileImage] = useState({
        file: '',
    })

    function onSubmit(data: any) {
        setLoading(true);
        data.file = data?.file?.[0];
        // data.tags = data?.tags?.value;
        data.is_active = data?.is_active ? 1 : 0;
        if (formMode === 'update') data.id = selectedData?.id;
        const apiFunc = formMode === 'create' ? 'createTestimonial' : 'updateTestimonial';
        const message = formMode === 'create' ? 'created-message' : 'updated-message';
        services[apiFunc](data)
            .then((res: any) => {
                setLoading(false);
                toastMessage(formatMessage({ id: message }, { title: "testimonial" }));
                closeModal();
                refreshData();
            })
            .catch((err: any) => {
                setLoading(false);
                toastMessage(err?.data?.message, 'error');
            })
    }

    //RESET DEFAULTVALUE IN UPDATE MODE
    useEffect(() => {
        if (selectedData) {
            reset({
                full_name: selectedData.full_name,
                designation: selectedData.designation,
                // tags: departmentOptions?.find(x => x.value == selectedData.tags),
                comments: selectedData.comments,
                // social_profile: selectedData.social_profile ?? [buttonInitialState],
                order: selectedData.order,
                is_active: selectedData.is_active,
                file: null,
            });
            setFileImage({ file: selectedData.file_path_url })
        }

    }, [formMode === 'update']);

    return (
        <CustomDrawer
            formMode={formMode}
            open={open}
            onCancel={closeModal}
            onSubmit={handleSubmit(onSubmit)}
            title={headerText}
            size='md'
        >
            <Grid container spacing={2}>
                {loading && <HoverLoader />}
                <Grid item xs={12}>
                    <CustomTextBox
                        label={formatMessage({ id: "full-name" })}
                        name="full_name"
                        control={control}
                        error={errors?.full_name}
                        rules={validationRule.textbox({ required: true })}
                    />
                </Grid>
                {/* <Grid item xs={6}>
                    <CustomSelect
                        options={departmentOptions}
                        label={formatMessage({ id: "department" })}
                        name="tags"
                        control={control}
                        error={errors?.tags}
                        rules={validationRule.textbox({ required: true })}
                    />
                </Grid> */}
                <Grid item xs={12}>
                    <CustomTextBox
                        label={formatMessage({ id: "designation" })}
                        name="designation"
                        control={control}
                        error={errors?.designation}
                        rules={validationRule.textbox({ required: true })}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CustomTextArea
                        formLabel={formatMessage({ id: "comments" })}
                        name="comments"
                        control={control}
                        minRows={6}
                    />
                </Grid>
                {/* <Grid item xs={12}>
                    <CustomFileUpload
                        accept={FileAcceptData.IMAGES}
                        label={formatMessage({ id: "image" })}
                        error={errors?.file}
                        {...register('file', {
                            validate: (value: any) => value?.length > 0 ? fileValidate(value?.[0]) : true,
                        })}
                        defaultFileUrl={fileImage.file}
                        onClear={() => {
                            setValue(`file`, null);
                            clearErrors(`file`);
                        }}
                        onChange={(file: any) => setValue('file', file)}
                    />
                </Grid> */}

                {/* <Grid item xs={12}>
                    {fields.map((field, index) => (
                        <Box key={field.id} sx={{ display: 'flex', gap: 1, marginY: 1 }}>
                            <CustomTextBox
                                label={formatMessage({ id: "code" })}
                                name={`social_profile.${index}.code`}
                                control={control}
                                placeholder='facebook, instagram, tiktok ....'
                            />
                            <CustomTextBox
                                label={formatMessage({ id: "url" })}
                                name={`social_profile.${index}.url`}
                                control={control}
                            />
                            <IconButton onClick={() => remove(index)}>
                                <Delete color='error' />
                            </IconButton>
                        </Box>
                    ))}
                    <Button
                        startIcon={<Add />}
                        variant='contained'
                        onClick={() => append(buttonInitialState)}
                    >
                        Add Social Profile
                    </Button>
                </Grid> */}
                <Grid item xs={6}>
                    <CustomTextBox
                        label={formatMessage({ id: "order" })}
                        name="order"
                        type='number'
                        control={control}
                        error={errors?.order}
                    />
                </Grid>
                <Grid item xs={6}>
                    <CustomCheckBox
                        name="is_active"
                        label={formatMessage({ id: "is-active" })}
                        control={control}
                        isDefaultChecked
                    />
                </Grid>
            </Grid>
        </CustomDrawer>
    )
}

export default Form;