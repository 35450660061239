import { BASE_URI } from "utils/axios";

export const MASTER_API_ENDPOINTS = {
	// Master Requests
	LIST_ROLE: `${BASE_URI}/roles/list`,
	CREATE_ROLE: `${BASE_URI}/roles/save`,
	UPDATE_ROLE: `${BASE_URI}/roles/update`,
	DELETE_ROLE: `${BASE_URI}/roles/delete`,

	LIST_SPOKEN_LANGUAGE: `${BASE_URI}/spoken-language/list`,
	CREATE_SPOKEN_LANGUAGE: `${BASE_URI}/spoken-language/save`,
	UPDATE_SPOKEN_LANGUAGE: `${BASE_URI}/spoken-language/update`,
	DELETE_SPOKEN_LANGUAGE: `${BASE_URI}/spoken-language/delete`,

	LIST_MISCELLANEOUS: `${BASE_URI}/miscellaneous/list`,
	CREATE_MISCELLANEOUS: `${BASE_URI}/miscellaneous/save`,
	UPDATE_MISCELLANEOUS: `${BASE_URI}/miscellaneous/update`,
	DELETE_MISCELLANEOUS: `${BASE_URI}/miscellaneous/delete`,
};
