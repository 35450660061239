import { useEffect, useRef, useState } from "react";
// @ts-ignore
import styled from "@emotion/styled";
import { FormErrorMessage } from "./FormErrorMessage";
import { useToastMessage } from "hooks";
import { AxiosService } from "utils/axios";
import { Editor } from '@tinymce/tinymce-react';

const EditorContainerStyle = styled("div")(({ theme }: any) => ({
  overflow: "hidden",
  borderBottom: `1px solid ${theme.palette.action.hover}`,
}));

interface Props {
  onChange: (value: string) => void;
  defaultValue?: string;
  error?: string;
  formLabel?: string;
  height?: number;
  name: string;
  uuid?: string,
  placeholder?: string;
}
const CustomTextEditor = ({
  name,
  defaultValue,
  error,
  formLabel,
  placeholder,
  uuid,
  onChange
}: Props) => {
  const reactQuillRef: any = useRef<HTMLInputElement>(null);
  const [state, setState] = useState("");
  const { toastMessage } = useToastMessage();

  useEffect(() => {
    if (defaultValue) setState(defaultValue);
  }, [defaultValue]);

  const handleEditorChange = (content: string) => {
    setState(content);
    onChange(content);
  };

  return (
    <EditorContainerStyle>
      {formLabel && (
        <span className="text-13 font-medium" style={{ marginBottom: 8 }}>
          {formLabel}
        </span>
      )}
      <Editor
        id={name}
        apiKey='f8vpa350cvow0nl69v14f90g5zcl69s8d3zuhoyso2ss5smw'
        onInit={(evt, editor) => reactQuillRef.current = editor}
        value={state ?? ""}
        init={{
          height: 500,
          menubar: true,
          toolbar_mode: 'wrap',
          plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount fullscreen',
          toolbar: 'undo redo | fullscreen blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat codesample code',
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
          codesample_languages: [
            { text: 'HTML/XML', value: 'markup' },
            { text: 'JavaScript', value: 'javascript' },
            { text: 'CSS', value: 'css' },
            { text: 'PHP', value: 'php' },
            { text: 'Ruby', value: 'ruby' },
            { text: 'Python', value: 'python' },
            { text: 'Java', value: 'java' },
            { text: 'C', value: 'c' },
            { text: 'C#', value: 'csharp' },
            { text: 'C++', value: 'cpp' }
          ],
        //   images_upload_handler: async (blobInfo, progress) => {
        //     return new Promise((resolve, reject) => {
        //       const xhr = new XMLHttpRequest();
        //       xhr.upload.onprogress = (e) => {
        //         progress(e.loaded / e.total * 100);
        //       };

        //       xhr.onload = () => {
        //         if (xhr.status === 403) {
        //           reject({ message: 'HTTP Error: ' + xhr.status, remove: true });
        //           return;
        //         }

        //         if (xhr.status < 200 || xhr.status >= 300) {
        //           reject('HTTP Error: ' + xhr.status);
        //           return;
        //         }

        //         const json = JSON.parse(xhr.responseText);

        //         if (!json || typeof json.location != 'string') {
        //           reject('Invalid JSON: ' + xhr.responseText);
        //           return;
        //         }

        //         resolve(json.location);
        //       };

        //       xhr.onerror = () => {
        //         reject('Image upload failed due to a XHR Transport error. Code: ' + xhr.status);
        //       };

        //       const formData = new FormData();
        //       formData.append('uuid', uuid);
        //       formData.append('files', blobInfo.blob(), blobInfo.filename());

        //       new AxiosService().post('/files', formData).then((res: any) => {
        //         const url = res?.result?.[0]?.url;
        //         resolve(url)
        //       }).catch(e => {
        //         toastMessage('You could only upload images.', 'error');
        //         reject(e);
        //       })

        //     });
        //   },
        }}
        onEditorChange={handleEditorChange}
      />

      <FormErrorMessage error={error} />
    </EditorContainerStyle>
  );
};

CustomTextEditor.defaultProps = {
  defaultValue: "<p></br></p>",
};

export { CustomTextEditor };
