import { images } from "utils/constants";
import { Buffer } from 'buffer';

export function findCommonItems(arr1: any, arr2: any) {
    return arr1?.some((v: any) => arr2?.indexOf(v) !== -1);
}

export const blobToFile = (theBlob: Blob, fileName: string): File => {
    return new File(
        [theBlob as any], // cast as any
        fileName,
        {
            lastModified: new Date().getTime(),
            type: theBlob.type
        }
    )
}

export function getUpdateImageFormat(objectUrl: any) {
    let previewImage;
    let fileName;

    if (objectUrl.startsWith('data:image')) {
        // Handle base64 image URL
        previewImage = objectUrl;
        fileName = 'base64image';
    } else {
        const split = objectUrl?.split('.');
        fileName = objectUrl?.split('/')?.pop();

        if (['pdf', 'xls', 'xlsx', 'docx'].includes(split?.pop())) {
            previewImage = images.document;
        } else {
            // Handle other regular image URLs
            previewImage = objectUrl;
        }
    }

    return { objectUrl, previewImage, fileName };
}

export function getUploadImageFormat(objectUrl: any) {
    let previewImage;
    if (objectUrl) {
        const split = objectUrl?.name?.split('.');
        if (['pdf', 'xls', 'xlsx', 'docx'].includes(split?.pop())) {
            objectUrl = URL.createObjectURL(objectUrl);
            previewImage = images.document;
        } else {
            objectUrl = URL.createObjectURL(objectUrl);
            previewImage = URL.createObjectURL(objectUrl);
        }
    }

    return { objectUrl, previewImage };
}

export function convertToDateFormat(dateString: string): Date | null {
    // Check if the input string has the correct length
    if (dateString.length !== 6) {
        console.error("Invalid date string length");
        return null;
    }
    const currentYear = new Date().getFullYear();
    const currentYearPrefix = String(currentYear).substring(0, 2);
    const currentYearPostfix = String(currentYear).substring(2, 4);
    // Extracting day, month, and year from the input string
    const year = Number(dateString.substring(0, 2));
    const month = Number(dateString.substring(2, 4)) - 1;
    const day = Number(dateString.substring(4, 6));
    const next10year = Number(currentYearPostfix) + 10;
    // Parsing the components into integers
    const dayNumber = day > 9 ? day : Number(`0${day}`);
    const monthNumber = month > 9 ? month : Number(`0${month}`);
    const yearNumber = next10year > year ? Number(`${currentYearPrefix}${year}`) : Number(`${+currentYearPrefix - 1}${year}`);
    // Check if the parsed components are valid
    if (isNaN(dayNumber) || isNaN(monthNumber) || isNaN(yearNumber)) {
        throw "Something wrong with date parse.";
    }
    // Creating a Date object using the components
    const date = new Date(yearNumber, monthNumber, dayNumber);
    // Check if the Date object represents a valid date
    if (isNaN(date.getTime())) {
        console.error("Invalid date");
        return null;
    }

    return date;
}

export function subtractYears(dateString: string | Date, year: number = 10): Date {
    const originalDate = typeof dateString === 'string' ? new Date(dateString) : dateString;

    // Subtract 10 years
    const resultDate = new Date(originalDate.getFullYear() - year, originalDate.getMonth(), originalDate.getDate());

    // Exclude the current day
    resultDate.setDate(resultDate.getDate() + 1);

    return resultDate;
}

export function extractFullName(fullName: string): { firstName: string, middleName: string } {
    // Split the full name into parts
    const nameParts = fullName.split(' ');

    // Extract everything after the first name as the middle name
    const middleName = nameParts?.length > 1 ? nameParts.slice(1).join(' ') : '';
    const firstName = nameParts?.length > 0 ? nameParts[0] : '';

    return { firstName, middleName };
}

export function dataUrlToFile(dataUrl: string, filename: string): File | undefined {
    const arr = dataUrl.split(',');
    if (arr.length < 2) { return undefined; }
    const mimeArr = arr[0].match(/:(.*?);/);
    if (!mimeArr || mimeArr.length < 2) { return undefined; }
    const mime = mimeArr[1];
    const buff = Buffer.from(arr[1], 'base64');
    return new File([buff], filename, { type: mime });
}

export function convertToDate(inputDate: string) {
    if (!inputDate || inputDate.trim() === "") {
        return null;
    }

    const isoDateRegex = /^(\d{4})-(\d{2})-(\d{2})$/;
    const alternateIsoDateRegex = /^(\d{2})-(\d{2})-(\d{4})$/;
    const longDateFormatRegex = /^(\d{1,2})\s+([a-zA-Z]+)\s+(\d{4})$/;
    const slashDateFormatRegex = /^(\d{2})\/(\d{2})\/(\d{4})$/;

    const isoDateMatch = inputDate.match(isoDateRegex);
    const alternateIsoDateMatch = inputDate.match(alternateIsoDateRegex);
    const longDateFormatMatch = inputDate.match(longDateFormatRegex);
    const slashDateFormatMatch = inputDate.match(slashDateFormatRegex);

    if (isoDateMatch) {
        const [, year, month, day] = isoDateMatch.map(Number);
        return new Date(year, month - 1, day);
    } else if (alternateIsoDateMatch) {
        const [, day, month, year] = alternateIsoDateMatch.map(Number);
        return new Date(year, month - 1, day);
    } else if (longDateFormatMatch) {
        const [, day, monthString, year] = longDateFormatMatch;
        const month = monthStringToNumber(monthString);
        return new Date(`${year}-${month.toString().padStart(2, '0')}-${day.padStart(2, '0')}`);
    } else if (slashDateFormatMatch) {
        const [, day, month, year] = slashDateFormatMatch.map(Number);
        return new Date(year, month - 1, day);
    } else {
        return null;
    }
}

// Function to convert month abbreviation to number
function monthStringToNumber(monthString: string): number {
    const months :any = {
        JAN: 1, FEB: 2, MAR: 3, APR: 4, MAY: 5, JUN: 6,
        JUL: 7, AUG: 8, SEP: 9, OCT: 10, NOV: 11, DEC: 12
    };

    const upperCaseMonth = monthString.toUpperCase();

    return months[upperCaseMonth] || 1; // Default to January if the abbreviation is not recognized
}
