import axios, { AxiosPromise } from "axios";
import { API_ENDPOINTS } from "redux/apiEndPoints";
import { AxiosService } from "utils/axios";

interface ParamsState {
  [key: string]: any
}

export const appServices = {
  logoutUser: (params: ParamsState): AxiosPromise => axios.get(API_ENDPOINTS.LOGOUT, { params }),
  dashboard: (): AxiosPromise => axios.get(API_ENDPOINTS.DASHBOARD),

  createBanner: (body: ParamsState) => new AxiosService().postForm(API_ENDPOINTS.CREATE_BANNER, body),
  updateBanner: (body: ParamsState) => new AxiosService().putForm(API_ENDPOINTS.UPDATE_BANNER, body),
  getAllBanner: (body: ParamsState) => new AxiosService().post(API_ENDPOINTS.LIST_BANNER, body),
  deleteBanner: (body: ParamsState) => new AxiosService().delete(API_ENDPOINTS.DELETE_BANNER, body),

  createService: (body: ParamsState) => new AxiosService().postForm(API_ENDPOINTS.CREATE_SERVICE, body),
  updateService: (body: ParamsState) => new AxiosService().putForm(API_ENDPOINTS.UPDATE_SERVICE, body),
  getAllService: (body: ParamsState) => new AxiosService().post(API_ENDPOINTS.LIST_SERVICE, body),
  deleteService: (body: ParamsState) => new AxiosService().delete(API_ENDPOINTS.DELETE_SERVICE, body),

  createServiceCategory: (body: ParamsState) => new AxiosService().postForm(API_ENDPOINTS.CREATE_SERVICE_CATEGORY, body),
  updateServiceCategory: (body: ParamsState) => new AxiosService().putForm(API_ENDPOINTS.UPDATE_SERVICE_CATEGORY, body),
  getAllServiceCategory: (body: ParamsState) => new AxiosService().post(API_ENDPOINTS.LIST_SERVICE_CATEGORY, body),
  deleteServiceCategory: (body: ParamsState) => new AxiosService().delete(API_ENDPOINTS.DELETE_SERVICE_CATEGORY, body),

  createProject: (body: ParamsState) => new AxiosService().postForm(API_ENDPOINTS.CREATE_PROJECT, body),
  updateProject: (body: ParamsState) => new AxiosService().putForm(API_ENDPOINTS.UPDATE_PROJECT, body),
  getAllProject: (body: ParamsState) => new AxiosService().post(API_ENDPOINTS.LIST_PROJECT, body),
  deleteProject: (body: ParamsState) => new AxiosService().delete(API_ENDPOINTS.DELETE_PROJECT, body),

  createProjectCategory: (body: ParamsState) => new AxiosService().postForm(API_ENDPOINTS.CREATE_PROJECT_CATEGORY, body),
  updateProjectCategory: (body: ParamsState) => new AxiosService().putForm(API_ENDPOINTS.UPDATE_PROJECT_CATEGORY, body),
  getAllProjectCategory: (body: ParamsState) => new AxiosService().post(API_ENDPOINTS.LIST_PROJECT_CATEGORY, body),
  deleteProjectCategory: (body: ParamsState) => new AxiosService().delete(API_ENDPOINTS.DELETE_PROJECT_CATEGORY, body),

  createProjectImage: (body: ParamsState) => new AxiosService().postForm(API_ENDPOINTS.CREATE_PROJECT_IMAGE, body),
  getAllProjectImage: (body: ParamsState) => new AxiosService().post(API_ENDPOINTS.LIST_PROJECT_IMAGE, body),
  deleteProjectImage: (body: ParamsState) => new AxiosService().delete(API_ENDPOINTS.DELETE_PROJECT_IMAGE, body),

  createClient: (body: ParamsState) => new AxiosService().postForm(API_ENDPOINTS.CREATE_CLIENT, body),
  updateClient: (body: ParamsState) => new AxiosService().putForm(API_ENDPOINTS.UPDATE_CLIENT, body),
  getAllClient: (body: ParamsState) => new AxiosService().post(API_ENDPOINTS.LIST_CLIENT, body),
  deleteClient: (body: ParamsState) => new AxiosService().delete(API_ENDPOINTS.DELETE_CLIENT, body),

  createTeam: (body: ParamsState) => new AxiosService().postForm(API_ENDPOINTS.CREATE_TEAM, body),
  updateTeam: (body: ParamsState) => new AxiosService().putForm(API_ENDPOINTS.UPDATE_TEAM, body),
  getAllTeam: (body: ParamsState) => new AxiosService().post(API_ENDPOINTS.LIST_TEAM, body),
  deleteTeam: (body: ParamsState) => new AxiosService().delete(API_ENDPOINTS.DELETE_TEAM, body),

  createUser: (body: ParamsState) => new AxiosService().postForm(API_ENDPOINTS.CREATE_USER, body),
  updateUser: (body: ParamsState) => new AxiosService().putForm(API_ENDPOINTS.UPDATE_USER, body),
  getAllUser: (body: ParamsState) => new AxiosService().post(API_ENDPOINTS.LIST_USER, body),
  getUserByUuid: (body: ParamsState) => new AxiosService().get(API_ENDPOINTS.GET_USER_BY_UUID, body),
  deleteUser: (params: ParamsState) => new AxiosService().delete(API_ENDPOINTS.DELETE_USER, params),
  getUserLookup: () => new AxiosService().get(API_ENDPOINTS.USER_LOOKUP),
  updateUserFlag: (body: ParamsState) => new AxiosService().put(API_ENDPOINTS.UPDATE_USER_FLAG, body),

  createExpense: (body: ParamsState) => new AxiosService().post(API_ENDPOINTS.CREATE_EXPENSE, body),
  getAllExpense: (body: ParamsState) => new AxiosService().post(API_ENDPOINTS.LIST_EXPENSE, body),
  deleteExpense: (params: ParamsState) => new AxiosService().delete(API_ENDPOINTS.DELETE_EXPENSE, params),

  createGallery: (body: ParamsState) => new AxiosService().postForm(API_ENDPOINTS.CREATE_GALLERY, body),
  updateGallery: (body: ParamsState) => new AxiosService().putForm(API_ENDPOINTS.UPDATE_GALLERY, body),
  getAllGallery: (body: ParamsState) => new AxiosService().post(API_ENDPOINTS.LIST_GALLERY, body),
  deleteGallery: (body: ParamsState) => new AxiosService().delete(API_ENDPOINTS.DELETE_GALLERY, body),

  createGalleryCategory: (body: ParamsState) => new AxiosService().postForm(API_ENDPOINTS.CREATE_GALLERY_CATEGORY, body),
  updateGalleryCategory: (body: ParamsState) => new AxiosService().putForm(API_ENDPOINTS.UPDATE_GALLERY_CATEGORY, body),
  getAllGalleryCategory: (body: ParamsState) => new AxiosService().post(API_ENDPOINTS.LIST_GALLERY_CATEGORY, body),
  deleteGalleryCategory: (body: ParamsState) => new AxiosService().delete(API_ENDPOINTS.DELETE_GALLERY_CATEGORY, body),

  createGalleryImage: (body: ParamsState) => new AxiosService().postForm(API_ENDPOINTS.CREATE_GALLERY_IMAGE, body),
  getAllGalleryImage: (body: ParamsState) => new AxiosService().post(API_ENDPOINTS.LIST_GALLERY_IMAGE, body),
  deleteGalleryImage: (body: ParamsState) => new AxiosService().delete(API_ENDPOINTS.DELETE_GALLERY_IMAGE, body),

  createPage: (body: ParamsState) => new AxiosService().postForm(API_ENDPOINTS.CREATE_PAGE, body),
  updatePage: (body: ParamsState) => new AxiosService().putForm(API_ENDPOINTS.UPDATE_PAGE, body),
  getAllPage: (body: ParamsState) => new AxiosService().post(API_ENDPOINTS.LIST_PAGE, body),
  deletePage: (body: ParamsState) => new AxiosService().delete(API_ENDPOINTS.DELETE_PAGE, body),

  createTestimonial: (body: ParamsState) => new AxiosService().postForm(API_ENDPOINTS.CREATE_TESTIMONIAL, body),
  updateTestimonial: (body: ParamsState) => new AxiosService().putForm(API_ENDPOINTS.UPDATE_TESTIMONIAL, body),
  getAllTestimonial: (body: ParamsState) => new AxiosService().post(API_ENDPOINTS.LIST_TESTIMONIAL, body),
  deleteTestimonial: (body: ParamsState) => new AxiosService().delete(API_ENDPOINTS.DELETE_TESTIMONIAL, body),

  getAllEnqiryList: (params: ParamsState) => new AxiosService().get(API_ENDPOINTS.ENQUIRY_LIST, params),

  generateReport: (params: ParamsState) => new AxiosService().get(API_ENDPOINTS.GENERATE_REPORT, params),

  getAbout: () => new AxiosService().get(API_ENDPOINTS.GET_ABOUT),
  updateAbout: (body: ParamsState) => new AxiosService().putForm(API_ENDPOINTS.UPDATE_ABOUT, body),

  logout: (): AxiosPromise => axios.post(API_ENDPOINTS.LOGOUT),

}