import { Link as RouterLink } from 'react-router-dom';
import { Box } from '@mui/material';
import { images } from 'utils/constants';
import { useSelector } from 'react-redux';
import { RootReducerState } from 'utils/types';

export default function Logo({ disabledLink = false, sx }: any) {
  const { userData } = useSelector(({ auth }: RootReducerState) => ({ userData: auth.data }));
  const logo = <Box
    component="img"
    src={userData?.account_logo ? userData?.account_logo: images.logo}
    sx={{  width: '100%', ...sx }}
  />;

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
