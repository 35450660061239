import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
//
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import useResponsive from 'hooks/useResponsive';
import { useDispatch } from 'react-redux';
import { updateUtility } from 'redux/actions';
// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  // [theme.breakpoints.up('lg')]: {
  //   paddingTop: APP_BAR_DESKTOP + 24,
  //   paddingLeft: theme.spacing(2),
  //   paddingRight: theme.spacing(2)
  // }
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const isDesktop = useResponsive("up", "lg");
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isDesktop) {
      dispatch(updateUtility({ toggleSidebarMenu: false }));
    }
  }, [isDesktop])

  return (
    <RootStyle>
      <DashboardNavbar />
      <DashboardSidebar />
      <MainStyle>
        <Outlet />
      </MainStyle>
    </RootStyle>
  );
}
