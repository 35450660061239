// @mui
import { Grid, Typography } from "@mui/material";
import { useToastMessage } from "hooks";
// components
import PageLayout from "layouts/PageLayout";
import { useEffect, useState } from "react";
import { services } from "redux/services";

import { AppWidgetSummary } from "sections/dashboard";
import WorkIcon from '@mui/icons-material/Work';
import EngineeringIcon from '@mui/icons-material/Engineering';
import AttributionIcon from '@mui/icons-material/Attribution';
import AppWebsiteVisits from "sections/dashboard/AppWebsiteVisits";

export default function DashboardApp() {
  const [loading, setLoading] = useState<boolean>(false);
  const [dashboard, setDashboard] = useState<any>({
    candidate: 0,
    company: 0,
    agent: 0,
    account: 0
  });
  const { toastMessage } = useToastMessage();

  useEffect(() => {
    services
      .dashboard()
      .then((res: any) => {
        setLoading(false);
        setDashboard(res?.data?.result);
      })
      .catch((err) => {
        setLoading(false);
        toastMessage(err.message, "error");
      });
  }, []);

  return (
    <PageLayout title="Dashboard" isList={false} hideToolbar>
      <Typography variant="h4" sx={{ mb: 5 }}>
        Hi, Welcome back
      </Typography>

      <Grid container spacing={3}>

        <Grid item xs={12} sm={6} md={3}>
          <AppWidgetSummary
            title="Candidate"
            total={dashboard.candidate}
            icon={<EngineeringIcon />}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <AppWidgetSummary
            title="Company"
            total={dashboard.company}
            color="info"
            icon={<WorkIcon />}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <AppWidgetSummary
            title="Agent"
            total={dashboard.agent}
            color="warning"
            icon={<AttributionIcon />}
          />
        </Grid>


        {/* <Grid item xs={12}>
          <AppWebsiteVisits
            title="Website Visits"
            subheader="(+43%) than last year"
            chartLabels={[
              '01/01/2003',
              '02/01/2003',
              '03/01/2003',
              '04/01/2003',
              '05/01/2003',
              '06/01/2003',
              '07/01/2003',
              '08/01/2003',
              '09/01/2003',
              '10/01/2003',
              '11/01/2003',
            ]}
            chartData={[
              {
                name: 'Team A',
                type: 'column',
                fill: 'solid',
                data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30],
              },
              {
                name: 'Team B',
                type: 'area',
                fill: 'gradient',
                data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
              },
              {
                name: 'Team C',
                type: 'line',
                fill: 'solid',
                data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],
              },
            ]}
          />
        </Grid> */}
      </Grid>
    </PageLayout>
  );
}
