import { useState } from 'react';

import Grid from '@mui/material/Grid';

import { CustomTab } from 'components';
import ExpenseItem from './expenseItem';
import Report from './report';
import { Container } from '@mui/material';

interface Props {

}

const AdvanceSearch = (props: Props) => {
    const [activeTab, setActiveTab] = useState<number>(0);
    return (
        <Container maxWidth="xl" style={{ maxWidth: '100%' }}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <CustomTab
                        tabs={['Daily Income', 'Daily Expense', 'Report']}
                        onChange={(tab) => { setActiveTab(tab) }}
                    />
                </Grid>

                <Grid item xs={12}>
                    {activeTab === 0 && <ExpenseItem category={'income'}/>}
                    {activeTab === 1 && <ExpenseItem category={'expense'}/>}
                    {activeTab === 2 && <Report />}
                </Grid>
            </Grid>
        </Container>
    )
}

export default AdvanceSearch;